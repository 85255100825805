


const AdmPanel = ()=>{

    return (
        <>
        </>
    )
}


export default AdmPanel