import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Navbar from './include/Navbar';
import { Container, Card, CardContent, Typography, TextField, Button, Grid } from '@mui/material';

function getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
}

const PasswordResetConfirm = () => {
    const { uidb64, token } = useParams();
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const navigate = useNavigate();

    const onSubmit = e => {
        e.preventDefault();
        const cookie = getCookie('csrftoken');
        fetch(`${process.env.REACT_APP_API_URL}api/users/password-reset-confirm/${uidb64}/${token}/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': cookie 
            },
            body: JSON.stringify({ password, confirm_password: confirmPassword })
        })
        .then(response => response.json())
        .then(data => {
            if (data.success) {
                navigate('/login');
            } else {
                console.error('Error resetting password:', data.error);
            }
        })
        .catch(error => {
            console.error('Error:', error);
        });
    };

    return (
        <>
            <Navbar />
            <Container maxWidth="sm" style={{ padding: '2rem 0' }}>
                <Card>
                    <CardContent>
                        <Typography variant="h5" align="center" gutterBottom>
                            Reset Password
                        </Typography>
                        <form onSubmit={onSubmit}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        type="password"
                                        label="New Password"
                                        variant="outlined"
                                        onChange={(e) => setPassword(e.target.value)}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        type="password"
                                        label="Confirm Password"
                                        variant="outlined"
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Button 
                                        type="submit" 
                                        variant="contained" 
                                        color="primary" 
                                        fullWidth 
                                        size="large">
                                        Reset Password
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </CardContent>
                </Card>
            </Container>
        </>
    );
};

export default PasswordResetConfirm;


// import React, { useState } from 'react';
// import { useParams, useNavigate } from 'react-router-dom';
// import Navbar from './include/Navbar';
// // import NavbarComponent from "./includes/Navbar";
// // import './PasswordResetConfirm.css';

// function getCookie(name) {
//     let cookieValue = null;
//     if (document.cookie && document.cookie !== '') {
//         const cookies = document.cookie.split(';');
//         for (let i = 0; i < cookies.length; i++) {
//             const cookie = cookies[i].trim();
//             if (cookie.substring(0, name.length + 1) === (name + '=')) {
//                 cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
//                 break;
//             }
//         }
//     }
//     return cookieValue;
// }


// const PasswordResetConfirm = () => {
//     const { uidb64, token } = useParams();
//     const [password, setPassword] = useState('');
//     const [confirmPassword, setConfirmPassword] = useState('');
//     const navigate = useNavigate();

//     const onSubmit = e => {
//         e.preventDefault();
//         const cookie = getCookie('csrftoken')
//         console.log(cookie)
//         fetch(`${process.env.REACT_APP_API_URL}api/users/password-reset-confirm/${uidb64}/${token}/`, {
//             method: 'POST',
//             headers: {
//                 'Content-Type': 'application/json',
//                 'X-CSRFToken': cookie 
//             },
//             body: JSON.stringify({ password, confirm_password: confirmPassword })
//         })
//         .then(response => response.json())
//         .then(data => {
//             if (data.success) {
//                 navigate('/login');
//             } else {
//                 console.error('Error resetting password:', data.error);
//             }
//         })
//         .catch(error => {
//             console.error('Error:', error);
//         });
//     };

//     return (
//         <>
//             <Navbar />
//             <div className="container">
//                 <div className="row">
//                     <div className="col s12 card">
//                         <form onSubmit={onSubmit}>
//                             <div className="container">
//                                 <h3 className="center">Reset Password</h3>
//                                 <div className="row">
//                                     <div className="input-field col s12">
//                                         <input 
//                                             className="validate" 
//                                             type="password" 
//                                             name="password" 
//                                             id="password" 
//                                             onChange={(e) => setPassword(e.target.value)} 
//                                             required 
//                                         />
//                                         <label htmlFor="password">New Password:</label>
//                                     </div>
//                                 </div>
//                                 <div className="row">
//                                     <div className="input-field col s12">
//                                         <input 
//                                             className="validate" 
//                                             type="password" 
//                                             name="confirm_password" 
//                                             id="confirm_password" 
//                                             onChange={(e) => setConfirmPassword(e.target.value)} 
//                                             required 
//                                         />
//                                         <label htmlFor="confirm_password">Confirm Password:</label>
//                                     </div>
//                                 </div>
//                                 <button type="submit" className="col s12 btn btn-large login-button">
//                                     Reset Password
//                                 </button>
//                             </div>
//                         </form>
//                     </div>
//                 </div>
//             </div>
//         </>
//     );
// };

// export default PasswordResetConfirm;