import React, { useState, useEffect } from 'react';
import { getProjects, handleNewProject, handleDeleteOption, handleChooseOption, setData, setSharedData } from './utils/ProjectFunctions';
import { useSelector } from 'react-redux';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { MapComponent } from './utils/MapComponent';
import "../styles/Project.css";
import Navbar from './include/Navbar';
import { loadingPage } from './utils/Loading';
import axios from 'axios';

// Import MUI components
import { Box, Button, Card, CardContent, CardMedia, Typography, Grid, IconButton, Input, TextField } from '@mui/material';
import OpenInBrowserIcon from '@mui/icons-material/OpenInBrowser';
import DeleteIcon from '@mui/icons-material/Delete';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';

const API_URL = process.env.REACT_APP_API_URL;

const Map = ({ project, rasters, setRasters, vectors, setVectors, isSharedView }) => {
    return (
        <>
            <MapComponent
                rasters={rasters}
                setRasters={setRasters}
                vectors={vectors}
                setVectors={setVectors}
                projectid={project.id}
                project={project}
                savetomemory={false}
                isSharedView={isSharedView}
            />
        </>
    );
};

function Project() {
    const navigate = useNavigate();
    const { isAuthenticated, user, loading } = useSelector(state => state.user);
    const [project, setProject] = useState(null);
    const [projects, setProjects] = useState([]);
    const [projectTextInput, setProjectTextInput] = useState(false);
    const [inputValue, setInputValue] = useState("");
    const [rasters, setRasters] = useState([]);
    const [vectors, setVectors] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const { project_id, token } = useParams();

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                if (token) {
                    const response = await axios.get(`${API_URL}/api/main/share/${token}/`);
                    if (!project) {
                        await setSharedData(response.data, setProject, setRasters, setVectors);
                    }
                } else if (project_id && project === null) {
                    setData(setProject, setRasters, project_id, projects, navigate, setVectors);
                } else if (projects.length === 0) {
                    await getProjects(setProjects);
                }
            } catch (error) {
                console.error('Error loading shared project:', error);
            }
            setIsLoading(false);
        };

        if (!project) {
            fetchData();
        }
    }, [project_id, token, project]);

    if (!isAuthenticated && !loading && user === null && !token) {
        return <Navigate to='/login' />;
    }

    const chooseProject = (
        <>
            <Navbar />
            <Box sx={{ padding: 2 }}>
                <Grid container spacing={2}>
                    {projects.map((project, index) => (
                        <Grid item xs={12} sm={6} md={3} key={index}>
                            <Card>
                                <CardMedia
                                    component="img"
                                    height="140"
                                    image={project.thumbnail || `${process.env.PUBLIC_URL}/thumbnail_map.png`}
                                    alt={`Project ${index + 1}`}
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div">
                                        {project.name}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        <b>Last updated:</b> {project.updated_at}
                                    </Typography>
                                </CardContent>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: 1 }}>
                                    <IconButton color="primary" onClick={() => handleChooseOption(project.id, navigate)}>
                                        <OpenInBrowserIcon />
                                    </IconButton>
                                    <IconButton color="secondary" onClick={() => handleDeleteOption(project.id, setProjects)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </Box>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
                <Box sx={{ textAlign: 'center', marginTop: 2 }}>
                    {projectTextInput ? (
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 1 }}>
                            <TextField
                                label="Enter project name"
                                variant="outlined"
                                value={inputValue}
                                onChange={(e) => setInputValue(e.target.value)}
                            />
                            <Button variant="contained" onClick={() => handleNewProject(setProjects, inputValue, navigate)}>
                                Create
                            </Button>
                            <Button variant="outlined" onClick={() => setProjectTextInput(false)}>
                                Cancel
                            </Button>
                        </Box>
                    ) : (
                        <Button startIcon={<CreateNewFolderIcon />} variant="contained" onClick={() => setProjectTextInput(true)}>
                            New Project
                        </Button>
                    )}
                </Box>
            </Box>
        </>
    );

    return (
        <>
            {isLoading ? loadingPage() : project ? (
                <Map
                    project={project}
                    rasters={rasters}
                    setRasters={setRasters}
                    vectors={vectors}
                    setVectors={setVectors}
                    isSharedView={!!token}
                />
            ) : chooseProject}
        </>
    );
}

export default Project;