import React from 'react';
import Navbar from './include/Navbar';
import Footer from './include/Footer';
import { Card, CardContent, CardMedia, Typography, Grid, IconButton, Link, Icon } from '@mui/material';
import { LinkedIn, GitHub, Email, Work, Coffee, Person } from '@mui/icons-material';
import '../styles/About.css';

const url = process.env.PUBLIC_URL;

const About = () => {
    return (
        <>
            <Navbar />
            <div className="container">
                <Typography variant="h3" align="center" gutterBottom>Developers</Typography>
                
                <Grid container spacing={4} justifyContent="center">
                    <Grid item xs={12} md={6}>
                        <Card>
                            <CardMedia
                                component="img"
                                // height="300"
                                maxWidth="100"
                                image={`${url}/FelipePinto2.JPG`}
                                alt="Felipe Matheus Pinto"
                            />
                            <CardContent>
                                <Typography variant="h5">Felipe Matheus Pinto</Typography>
                                <Typography variant="body1" paragraph>
                                    Full stack developer, with GIS expertise, GIS and Remote Sensing specialist, and Data Scientist. Access my social media:
                                </Typography>
                                <Grid container spacing={1} justifyContent="center">
                                    <Grid item>
                                        <IconButton component={Link} href="https://www.linkedin.com/in/felipe-matheus-pinto-70042b113" target="_blank" rel="noreferrer">
                                            <LinkedIn />
                                        </IconButton>
                                    </Grid>
                                    <Grid item>
                                        <IconButton component={Link} href="https://stackoverflow.com/users/14254779/felipe-matheus-pinto" target="_blank" rel="noreferrer">
                                            <img src={`${url}/icons8-stack-overflow-48.png`} width="25" height="25" alt="Stack Overflow" />
                                        </IconButton>
                                    </Grid>
                                    <Grid item>
                                        <IconButton component={Link} href="https://github.com/felipempinto" target="_blank" rel="noreferrer">
                                            <GitHub />
                                        </IconButton>
                                    </Grid>
                                    <Grid item>
                                        <IconButton component={Link} href="mailto:felipempfreelancer@gmail.com?subject=Deep forest help" target="_blank" rel="noreferrer">
                                            <Email />
                                        </IconButton>
                                    </Grid>
                                    <Grid item>
                                        <IconButton component={Link} href="https://www.upwork.com/freelancers/~01dac11ce87134abd6" target="_blank" rel="noreferrer">
                                            <Work />
                                        </IconButton>
                                    </Grid>
                                    <Grid item>
                                        <IconButton component={Link} href="https://www.buymeacoffee.com/felipempinto" target="_blank" rel="noreferrer">
                                            <Coffee />
                                        </IconButton>
                                    </Grid>
                                    <Grid item>
                                        <IconButton component={Link} href="https://medium.com/@felipempfreelancer" target="_blank" rel="noreferrer">
                                            <img src={`/medium-logo.png`} width="25" height="25" alt="Medium" />
                                        </IconButton>
                                    </Grid>
                                    <Grid item>
                                        <IconButton component={Link} href="https://felipemp.com" target="_blank">
                                            <Person />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Card>
                            <CardMedia
                                component="img"
                                // height="300"
                                image={`${url}/felipe_limeira.png`}
                                alt="Felipe Limeira"
                            />
                            <CardContent>
                                <Typography variant="h5">Felipe Limeira</Typography>
                                <Typography variant="body1" paragraph>
                                    Desenvolvedor GIS com experiência em Automações de processos, manipulação de Banco de dados e Desenvolvimento Web.
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </div>
            <Footer />
        </>
    );
};

export default About;
